import React from "react";
import { Routes, Route } from "react-router-dom";
import Contact from "../pages/Contact";
import Cremes from "../pages/Cremes";
import Home from "../pages/Home";
import Prijzen from "../pages/Prijzen";
import VergoedingZorgverzekeraar from "../pages/VergoedingZorgverzekeraar";

const Main = () => {
  return (
    <div className="container">
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/prijzen" element={<Prijzen title="Prijzen" />} />
        <Route
          path="/vergoeding-zorgverzekeraar"
          element={
            <VergoedingZorgverzekeraar title="Vergoeding Zorgverzekeraar" />
          }
        />
        <Route path="/cremes" element={<Cremes title="Crèmes" />} />
        <Route path="/contact" element={<Contact title="Contact" />} />
      </Routes>
    </div>
  );
};

export default Main;
