import React, { useEffect } from "react";
import janny_sauren from "../img/janny_sauren.png";

function Home() {
  useEffect(() => {
    document.title = "Pedicure Sauren";
  }, []);

  return (
    <>
      <div className="page-content-wrapper">
        <div className="page-content-left">
          <h2 className="customers-info">
            <span className="highlight">KLANTEN STOP:</span> op dit moment neem ik geen nieuwe klanten aan.
          </h2>
          <h1 className="page-title">Over mij</h1>

          <p className="page-text">
            Mag ik me even voorstellen? Ik ben Janny Sauren uit Heel (Maasgouw) en behaalde in juni
            1999 mijn pedicurediploma. <br /> <br /> Het theorie-examen bestond
            uit een aantal onderdelen waaronder pathologie, anatomie,
            fysiologie, schoenkennis, en natuurlijk nog een aantal onderdelen
            gericht op het pedicuren. <br /> <br />
            Het praktijkexamen bestond uit een complete voetbehandeling, zoals
            anamnese, blauwdrukken en de pedicure behandeling. <br /> <br /> In
            de loop der jaren heb ik mijn diploma's behaald voor het uitvoeren
            van specialistische behandelingen. <br /> <br /> Met enige regelmaat
            volg ik verdiepingscursussen zodat ik allerlei specialisaties uit
            kan voeren zoals de diabetische, reumatische voet behandelen en
            screenen. U kunt ook bij mij terecht voor nagelbeugeltechniek,
            nagelreparatie/regulatie en orthesiologie toepassingen.
          </p>
        </div>

        <div className="page-content-right">
          <img src={janny_sauren} alt="Janny Sauren" />
        </div>
      </div>
    </>
  );
}

export default Home;
