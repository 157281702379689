import React, { useEffect } from "react";
import mint from "../img/mint.png";
import kloven from "../img/kloven.png";
import balsem from "../img/balsem.png";

function Cremes({ title }) {
  useEffect(() => {
    document.title = "Pedicure Sauren - " + title;
  }, []);

  return (
    <>
      <div className="page-content-wrapper">
        <div className="page-content-left">
          <h1 className="page-title">Crèmes</h1>

          <div className="list-container">

            <div className="card">
              <div className="card-title">Gehwol mint</div>
              <img src={mint} alt="" />
              <div className="card-content">Voetschimmel & frisse voeten</div>
              <span className="card-price">€9,00</span>
            </div>

            <div className="card">
              <div className="card-title">Gehwol klovenzalf</div>
              <img src={kloven} alt="" />
              <div className="card-content">Kloven & droge voeten</div>
              <span className="card-price">€9,50</span>
            </div>

            <div className="card">
              <div className="card-title">Gehwol balsem</div>
              <img src={balsem} alt="" />
              <div className="card-content">Droge voeten</div>
              <span className="card-price">€9,50</span>
            </div>

          </div>
        </div>
      </div>
    </>
  );
}

export default Cremes;
