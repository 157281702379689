import React, { useEffect } from 'react';

function VergoedingZorgverzekeraar({ title }) {
  useEffect(() => {
    document.title = 'Pedicure Sauren - ' + title;
  }, []);

  return (
    <>
      <div className="page-content-wrapper">
        <div>
          <h1 className="page-title">Vergoeding Zorgverzekeraar</h1>

          <p className="page-text small">
            Bent u diabetespatiënt / reumapatiënt dan kunt u in sommige gevallen
            in aanmerking komen voor een vergoeding(tegemoetkoming) van de
            behandelingskosten.
            <br /> <br />* Informeer van te voren bij uw zorgverzekeraar waar u
            recht op heeft.
          </p>
        </div>
      </div>
    </>
  );
}

export default VergoedingZorgverzekeraar;
