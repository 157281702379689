import React, { useEffect } from "react";
import pin from "./../img/pinnen.png";

function Prijzen({ title }) {
  useEffect(() => {
    document.title = "Pedicure Sauren - " + title;
  }, []);

  return (
    <>
      <div className="page-content-wrapper">
        <div className="page-content-left">
          <h1 className="page-title">Prijzen</h1>

          <div className="pin-image">
            <img src={pin} alt="Pinnen ja graag" />
          </div>

          <div className="list-container">

            <div className="card">
              <span className="card-title">Voetbehandeling totaal</span>
              <p className="card-content">Verzorging van nagels, verwijderen van overtollig eelt, likdoorns en behandeling van kloven etc.</p>
              <span className="card-price">€25,00</span>
            </div>

            <div className="card">
              <span className="card-title">Behandeling diabetische / reumatische voet totaal</span>
              <p className="card-content">Verzorging van nagels, verwijderen van overtollig eelt, likdoorns en behandeling van kloven etc.</p>
              <span className="card-price">€27,00</span>
            </div>

            <div className="card">
              <span className="card-title">Verwijderen van likdoorn(s)</span>
              <p className="card-content"></p>
              <span className="card-price">€12,00</span>
            </div>

          </div>
        </div>
      </div>
    </>
  );
}

export default Prijzen;
