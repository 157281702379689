import React, { useEffect } from 'react';
import { ReactComponent as MailIcon } from './../img/mail_dark.svg';
import { ReactComponent as PhoneIcon } from './../img/phone_dark.svg';

function Contact({ title }) {
  useEffect(() => {
    document.title = 'Pedicure Sauren - ' + title;
  }, []);

  return (
    <>
      <div className="page-content-wrapper">
        <div>
          <h1 className="page-title">Contact</h1>

          <p className="page-text small">
            U kunt contact met mij opnemen via:
            <br /> <br />
            <span className="center-icon">
              <MailIcon />
              <a href="mailto:info@pedicure-sauren.nl">
                info@pedicure-sauren.nl
              </a>
            </span>
            <br />
            <span className="center-icon">
              <PhoneIcon />
              <a href="tel:0475-854543">0475-854543</a>
            </span>
          </p>
        </div>
      </div>
    </>
  );
}

export default Contact;
