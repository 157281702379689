import React from "react";
import { ReactComponent as MailIcon } from "./../img/mail.svg";
import { ReactComponent as PhoneIcon } from "./../img/phone.svg";
import { ReactComponent as ProvoetIcon } from "./../img/provoet.svg";

function Footer() {
  return (
    <div className="footer">
      <div className="footer-content">
        <span className="contact">Contact</span>
        <span>Janny Sauren</span>
        <span>
          <MailIcon />
          <a href="mailto:info@pedicure-sauren.nl">info@pedicure-sauren.nl</a>
        </span>
        <span>
          <PhoneIcon />
          <a href="tel:0475-854532l">0475-854543</a>
        </span>
      </div>
      <div className="footer-icon">
        <ProvoetIcon />
      </div>
    </div>
  );
}

export default Footer;
