import React, { useState } from "react";
import { NavLink } from "react-router-dom";
import { FaBars } from "react-icons/fa";
import { AiOutlineClose } from "react-icons/ai";

const Navbar = () => {
  const [showLinks, setShowLinks] = useState(false);

  return (
    <div className="navbar">
      <NavLink to="/">
        <div className="title">Pedicure Sauren</div>
      </NavLink>

      <div className="link-container">
        <div className="links" id={showLinks ? "hidden" : ""}>
          <NavLink
            onClick={() => setShowLinks(false)}
            to="/"
            className={({ isActive }) => (isActive ? "active" : "none")}
          >
            Home
          </NavLink>

          <NavLink
            onClick={() => setShowLinks(false)}
            to="/prijzen"
            className={({ isActive }) => (isActive ? "active" : "none")}
          >
            Prijzen
          </NavLink>

          <NavLink
            onClick={() => setShowLinks(false)}
            to="/vergoeding-zorgverzekeraar"
            className={({ isActive }) => (isActive ? "active" : "none")}
          >
            Vergoeding zorgverzekeraar
          </NavLink>

          <NavLink
            onClick={() => setShowLinks(false)}
            to="/cremes"
            className={({ isActive }) => (isActive ? "active" : "none")}
          >
            Crèmes
          </NavLink>

          <NavLink
            onClick={() => setShowLinks(false)}
            to="/contact"
            className={({ isActive }) => (isActive ? "active" : "none")}
          >
            Contact
          </NavLink>

          <button
            onClick={() => setShowLinks(!showLinks)}
            className="icon-button"
          >
            <AiOutlineClose />
          </button>
        </div>

        <button
          onClick={() => setShowLinks(!showLinks)}
          className="icon-button"
        >
          <FaBars />
        </button>
      </div>
    </div>
  );
};

export default Navbar;
